// @flow

/**
 * Module dependencies.
 */

import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  description: string,
  icon: string,
  title: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('grey200')};
  border-radius: ${units(1)};
  overflow: hidden;
  padding: ${units(5)} 10%;
  text-align: center;

  ${media.min('md')`
    padding:${units(10)} 20%;
  `}
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled.img`
  height: ${units(12)};
  margin-bottom: ${units(4)};
  width: ${units(12)};
`;

/**
 * `TeamStory` component.
 */

function TeamStory({ description, icon, title }: Props): Node {
  return (
    <Wrapper>
      {icon && <StyledImage src={icon} />}

      <Type.H4
        color={color('secondary')}
        marginBottom={units(3)}
      >
        <RawHtml>
          {title}
        </RawHtml>
      </Type.H4>

      <Type.H6>
        <RawHtml>
          {description}
        </RawHtml>
      </Type.H6>
    </Wrapper>
  );
}

/**
 * Export `TeamStory` component.
 */

export default TeamStory;
