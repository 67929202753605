// @flow

/**
 * Module dependencies.
 */

import { Box, Container, Grid } from 'components/core/layout';
import type { TemplateProps } from 'types/template';
import { getFluidImage } from 'utils/get-fluid-image';
import { graphql, useStaticQuery } from 'gatsby';
import { map } from 'lodash';
import { units } from '@seegno-labs/react-components/styles';
import FooterIconsSection from 'components/footer/footer-icons-section';
import Header from 'components/team/header';
import PageContainer from 'components/page-container';
import React, { type Node, useMemo } from 'react';
import TeamList from 'components/team/team-list';
import TeamStory from 'components/team/team-story';

/**
 * Team page query.
 */

const teamPageQuery = graphql`
  query {
    data: allSeegnoCmsTeamPage {
      nodes {
        content {
          header {
            backgroundImage {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
          }
          team {
            list {
              name
              jobTitle
              description
              linkedin
              twitter
              id
              image {
                file {
                  childImageSharp {
                    ...ResponsiveImage
                  }
                }
              }
            }
          }
          story {
            icon {
              file {
                publicURL
              }
            }
          }
          footerSection {
            buttonLabel
            buttonUrl
            leftIcon {
              file {
                publicURL
              }
            }
            leftText
            rightIcon {
              file {
                publicURL
              }
            }
            rightText
          }
        }
      }
    }
  }
`;

/**
 * `Team` template.
 */

function Team({ pageContext }: TemplateProps): Node {
  const { data } = useStaticQuery(teamPageQuery);
  const dataContent = data?.nodes[0]?.content;
  const metatags = pageContext?.metatags;
  const content = pageContext?.content;
  const teamMembers = useMemo(() => map(dataContent?.team?.list, member => ({
    ...member,
    image: getFluidImage(member)
  })), [dataContent.team.list]);

  return (
    <PageContainer
      footerElement={<FooterIconsSection {...dataContent?.footerSection} />}
      metatags={metatags}
    >
      <Header
        backgroundFluidImage={getFluidImage(dataContent?.header, 'backgroundImage')}
        description={content?.header?.description}
        title={content?.header?.title}
      />

      <Container>
        <Grid>
          <Box
            gridColumn={'1 / -1'}
            gridColumnLg={'3 / -3'}
            gridColumnMd={'2 / -2'}
            marginBottom={units(10)}
            marginBottomLg={units(20)}
            marginBottomMd={units(15)}
          >
            <TeamList members={teamMembers} />
          </Box>

          <Box
            gridColumn={'1 / -1'}
            gridColumnMs={'2 / -2'}
            marginBottom={units(10)}
            marginBottomLg={units(20)}
            marginBottomMd={units(15)}
          >
            <TeamStory
              description={content?.story?.description}
              icon={dataContent?.story?.icon?.file?.publicURL}
              title={content?.story?.title}
            />
          </Box>
        </Grid>
      </Container>
    </PageContainer>
  );
}

/**
 * Export `Team` template
 */

export default Team;
