// @flow

/**
 * Module dependencies.
 */

import { Box } from 'components/core/layout';
import { Card } from 'components/core/card';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { theme } from 'styled-tools';
import BackgroundImage from 'components/core/images/background-image';
import IconButton from 'components/core/button/icon-button';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import linkedinIcon from 'assets/svg/social/linkedin.svg';
import styled from 'styled-components';
import twitterIcon from 'assets/svg/social/twitter.svg';
import useBreakpoint from 'hooks/use-breakpoint';

/**
 * `TeamMember` type.
 */

type TeamMember = {|
  description: string,
  id: string,
  image: string,
  jobTitle: string,
  linkedin: string,
  name: string,
  twitter: string
|};

/**
 * `Props` type.
 */

type Props = {|
  members: Array<TeamMember>
|};

/**
 * `CardsGrid` styled component.
 */

const CardsGrid = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-gap: ${units(4)};

  ${media.min('sm')`
    flex-direction: row;
  `}
`;

/**
 * `TeamCard` styled component.
 */

const TeamCard = styled(Card)`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  grid-gap: ${units(4)} 0;
  padding: ${units(3)};
  text-align: center;

  ${media.min('sm')`
    flex: 1 1 calc(50% - ${units(4)});
  `}

  ${media.min('lg')`
    align-items: center;
    display: grid;
    grid-template-areas:
      'image       name        social'
      'description description description';
    grid-template-columns: min-content 1fr min-content;
    text-align: left;
  `}
`;

/**
 * `Avatar` styled component.
 */

const Avatar = styled.div`
  align-self: center;
  background: ${color('grey300')};
  clip-path: url(#slyk-mask);
  grid-area: image;
  height: ${units(11)};
  position: relative;
  width: ${units(11)};
`;

/**
 * `TeamList` component.
 */

function TeamList({ members }: Props): Node {
  const isMobile = useBreakpoint('ms', 'max');

  return (
    <CardsGrid>
      {members.map(member => (
        <TeamCard key={member.id}>
          <Avatar>
            <BackgroundImage fluid={member.image} />
          </Avatar>

          <Box paddingLeftLg={units(4)}>
            <Type.H5
              as={'h3'}
              color={color('secondary')}
              fontFamily={theme('typography.fontFamily.saira')}
              marginBottom={units(1)}
            >
              {member.name}
            </Type.H5>

            <Type.Paragraph
              color={color('grey400')}
              fontFamily={theme('typography.fontFamily.saira')}
            >
              {member.jobTitle}
            </Type.Paragraph>
          </Box>

          <Box
            alignSelf={'center'}
            alignSelfLg={'flex-start'}
            display={'flex'}
            gridArea={'social'}
            gridGap={units(1)}
            justifySelfLg={'flex-end'}
          >
            {member.twitter && (
              <IconButton
                href={member.twitter}
                icon={twitterIcon}
                rel={'noopener'}
                size={isMobile ? 'small' : 'extraSmall'}
                target={'_blank'}
              />
            )}

            {member.linkedin && (
              <IconButton
                href={member.linkedin}
                icon={linkedinIcon}
                rel={'noopener'}
                size={isMobile ? 'small' : 'extraSmall'}
                target={'_blank'}
              />
            )}
          </Box>

          <Box
            as={RawHtml}
            color={color('grey800')}
            gridArea={'description'}
            marginBottom={units(2)}
          >
            {member.description}
          </Box>
        </TeamCard>
      ))}
    </CardsGrid>
  );
}

/**
 * Export `TeamList` component.
 */

export default TeamList;
