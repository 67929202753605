// @flow

/**
 * Module dependencies.
 */

import { Box, Container, Fill, Grid } from 'components/core/layout';
import { RawHtml } from 'components/core/text-editor';
import { Type } from '@seegno-labs/react-components/typography';
import { color, units } from '@seegno-labs/react-components/styles';
import BackgroundImage from 'components/core/images/background-image';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  backgroundFluidImage?: Object,
  children?: Node,
  description: ?string,
  title: ?string
|};

/**
 * `HeaderSection` styled component.
 */

const HeaderSection = styled.header`
  margin-bottom: ${units(10)};
  padding-top: ${units(26)};
  position: relative;
`;

/**
 * `BackgroundWrapper` styled component.
 */

const BackgroundWrapper = styled(Fill)`
  bottom: -200px;
`;

/**
 * `StyledBackgroundImage` styled component.
 */

const StyledBackgroundImage = styled(BackgroundImage)`
  &,
  &::after,
  &::before {
    background-position: bottom center !important;
  }
`;

/**
 * `Header` component.
 */

function Header(props: Props): Node {
  const { backgroundFluidImage, children, description, title } = props;

  return (
    <HeaderSection>
      <BackgroundWrapper>
        <StyledBackgroundImage fluid={backgroundFluidImage} />
      </BackgroundWrapper>

      <Container>
        <Grid>
          <Box
            gridColumn={'1 / -1'}
            gridColumnLg={'4 / -4'}
            gridColumnMd={'3 / -3'}
            gridColumnMs={'2 / -2'}
            textAlign={'center'}
          >
            {title && (
              <Type.H1
                color={color('secondary')}
                marginBottom={units(4)}
              >
                <RawHtml>
                  {title}
                </RawHtml>
              </Type.H1>
            )}

            {description && (
              <Type.H5
                as={'h2'}
                color={color('secondary')}
              >
                <RawHtml>
                  {description}
                </RawHtml>
              </Type.H5>
            )}
          </Box>
        </Grid>

        {children}
      </Container>
    </HeaderSection>
  );
}

/**
 * Export `Header` component.
 */

export default Header;
